import React, { useEffect, useState, useContext } from "react";
import "../../style/main.scss";
import { Offcanvas } from "react-bootstrap";
import LayoutGrid from "./layoutGrid";
import LayoutRow from "./layoutRow";
import LayoutArtwork from "./layoutArtwork";
import Button from "../button";
import Input from "../inputs";
import Accordion from "react-bootstrap/Accordion";
import AsideFilter from "./asideFilter";
import NoContent from "../noContent";
import AllCollections from "../allcollections";
import { useNavigate } from "react-router-dom";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import Web3 from "web3";
import axios from "axios";
import _ from "lodash";

import filter from "../../assets/icon/filter.svg";
import grid from "../../assets/icon/display-grid-active.svg";
import row from "../../assets/icon/display-row-active.svg";
import artwork from "../../assets/icon/display-artwork-active.svg";
import sortby from "../../assets/icon/sort-by.svg";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import { ClearAllContext } from "../explore/ClearAllContext";
// collected are
import { getCollections } from "../../firebase/firebase";
import { useAccount, useWalletClient } from "wagmi";
import { propTypes } from "react-bootstrap/esm/Image";
import { Popover } from "antd";
import config from "../../config";
const Collected = (props) => {
  const { clearAll, setClearAll } = useContext(ClearAllContext);

  const clearFilter = () => {
    setClearAll(true);
  };
  console.log(props.nfts, "is owner");
  const [activeLayout, setactiveLayout] = useState("grid");
  const [mobileLayout, setMobileLayout] = useState(false);
  const { address } = useAccount();

  const [sgb, setSgb] = useState(null);
  const [flr, setFlr] = useState(null);
  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);


  // marketplace contract address

  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;


  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const [nftDetails, setNftDetails] = useState([]);

  const [web3, setWeb3] = useState(null);
  const [itemNumber, setItemNumber] = useState(0);


  // setting rpc url

  useEffect(() => {

    if (collectionDetails?.selectedNetwork === config.networks.songbird) {
      setWeb3(new Web3(config.rpc.songbird));
    }
    if (collectionDetails?.selectedNetwork === config.networks.flare) {
      setWeb3(new Web3(config.rpc.flr));
    }
  }, [collectionDetails]);


  // getting listed data form blockchain

  const getListNFTData = async () => {

    if (collectionDetails?.Approved && web3 !== null) {

      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }


      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      const data = await contract.methods
        .getNFTDetail(address, [`${collectionDetails.contractAddress}`])
        .call({ from: address });
      setNftDetails(data[0]);
    }
  };

  useEffect(() => {
    getListNFTData();
  }, [collectionDetails, web3]);


  useEffect(() => {
    setItemNumber(props?.nfts?.length);
    if (props?.nfts.length === 0) {
      props?.setLoading(false);
    }
  }, [nftDetails, props.nfts]);

  const navigate = useNavigate();

  const [showSideFilter, setShowSideFilter] = useState(false);
  const [Flag, setFlag] = useState(false);
  const [showFilter, setShowFilter] = useState("show filter");

  //  handle mobile filters
  const handleClose = () => setMobileLayout(false);
  const handleShow = () => setMobileLayout(true);

  // layout tabs handles

  const handleActiveLayout = (image) => {
    setactiveLayout(image);
    setMobileLayout(false);
  };

  // let layout handle the icon of currently selected layout
  let layout;
  if (activeLayout == "grid") {
    layout = grid;
  } else if (activeLayout == "row") {
    layout = row;
  } else if (activeLayout == "artwork") {
    layout = artwork;
  }

  const collectionSortFilter = [
    { value: "Recently Minted", label: "Recently Minted" },
    { value: "Lowest price", label: "Lowest price" },
    { value: "Highest price", label: "Highest price" },
    { value: "Recently Listed", label: "Recently Listed" },
  ];

  const [activeCollection, setActiveCollection] = useState("Recently Minted");

  const handleIPriceTick = (tick) => {
    setActiveCollection(tick);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  const [hide, setHide] = useState(false);
  const showMobileSideFilter = () => {
    setHide(!hide);
    if (windowWidth < 1000) {
      setShowSideFilter(true);
    } else {
      setFlag(!Flag);
    }
  };
  const closeMobileSideFilter = () => setShowSideFilter(false);

  // mobile sort filter
  const [collectionMobileFilter, setCollectionMobileFilter] = useState(false);
  const [activeCollectionFilter, setActiveCollectionFilter] =
    useState("Recently Minted");

  const showMobileSortFilter = () => {
    setCollectionMobileFilter(true);
  };
  const hideMobileSortFilter = () => setCollectionMobileFilter(false);
  const handleCollectionTick = (tick) => {
    setActiveCollectionFilter(tick);
    setCollectionMobileFilter(false);
  };



  let username = localStorage?.getItem("userName");
  const [collections, setCollections] = useState([]);
  const getUserCollections = async () => {
    const usercollections = await getCollections();
    setCollections(usercollections);
  };
  useEffect(() => {
    getUserCollections();
  }, []);

  const handleSelectedFilterChange = (newFilter) => {
    console.log(newFilter, "new filter change in colleciton");
  }

  let key = process.env.REACT_APP_CRYPTO_KEY;

  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;
        console.log(priceInUSD);
        setSgb(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;
        console.log(priceInUSD);
        setFlr(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };


  useEffect(() => {
    console.log(flr, "flr");
  }, [flr]);
  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);

  return (
    <div>
      <div className="collected-arts ">

        <div className="have-arts">
          <div className="collection-filter">
            <div className="left">
              <div
                className="show-filter cursor-pointer "
                onClick={showMobileSideFilter}
              >
                <span>
                  <img src={filter} alt="filter" id="filter" />
                </span>
                <label
                  htmlFor="filter"
                  className="medium text-black cursor-pointer ms-1 hide-on-mobile "
                >
                  {hide ? "Hide filter" : "show filter"}
                </label>
              </div>
              <div className="t-items">
                {
                  hide ? <label onClick={clearFilter} className="medium ms-4">Clear All</label> :
                    <label className="medium ms-4">{itemNumber} Items</label>
                }
              </div>
            </div>

            <div className="right">
              {/* layout tabs for desktop version */}
              <div className="collection-grid hide-on-mobile">
                <img
                  src={grid}
                  alt="grid"
                  className={
                    activeLayout === "grid" ? "active opacity-100 " : ""
                  }
                  onClick={() => handleActiveLayout("grid")}
                />
                <div className="divider"></div>
                <img
                  src={row}
                  alt="grid"
                  className={activeLayout === "row" ? "active opacity-100" : ""}
                  onClick={() => handleActiveLayout("row")}
                />
                <div className="divider"></div>
                <img
                  src={artwork}
                  alt="grid"
                  className={
                    activeLayout === "artwork" ? "active opacity-100" : ""
                  }
                  onClick={() => handleActiveLayout("artwork")}
                />
              </div>

              {/* layout tabs for  mobile menu */}
              <div className="collection-grid-sm">
                <span
                  className="hide-on-desktop d-flex align-items-center me-1"
                  onClick={handleShow}
                >
                  <img
                    src={layout}
                    alt="layout"
                    className={activeLayout === "grid" ? "active " : ""}
                  />
                </span>
              </div>

              {/* Sorting filter dropdown desktop*/}
              <div className="recent-collection filter dropdown hide-on-mobile ">
                <p
                  className="body-medium dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="hide-on-mobile">
                    {
                      collectionSortFilter.find(
                        (option) => option.value === activeCollection
                      )?.label
                    }
                  </span>
                  <span>
                    <img src={sortby} alt="sortby" />
                  </span>
                </p>
                <ul className="dropdown-menu">
                  {collectionSortFilter.map((option) => {
                    return (
                      <li
                        className="dropdown-item"
                        key={option.value}
                        onClick={() => handleIPriceTick(option.value)}
                      >
                        <img
                          src={tick}
                          alt="tick"
                          className={`${activeCollection === option.value
                            ? "active opacity-100"
                            : "opacity-0"
                            }`}
                        />
                        {option.label}
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Sorting filter dropdown Mobile*/}
              <div className="recent-collection filter hide-on-desktop ">
                <p className="body-medium " onClick={showMobileSortFilter}>
                  <span className="hide-on-mobile">
                    {
                      collectionSortFilter.find(
                        (option) => option.value === activeCollection
                      )?.label
                    }
                  </span>
                  <span>
                    <img src={sortby} alt="sortby" />
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* will show active Layout */}

          {activeLayout == "grid" ? (
            <LayoutGrid
              flag={Flag}
              nfts={props?.nfts}
              ApvNFT={props?.ApvNFT}
              nftDetails={nftDetails}
              isOwner={props?.isOwner}
              sortFilter={activeCollection}
              setItemNumber={setItemNumber}
              loading={props?.loading}
              setListedPercent={props?.setListedPercent}
              setFloorPrice={props?.setFloorPrice}
              setOwner={props?.setOwners}
              flr={flr}
              sgb={sgb}
              clearAll={clearAll}



            />
          ) : activeLayout == "row" ? (
            <LayoutRow
              flag={Flag}
              nfts={props.nfts}
              ApvNFT={props.ApvNFT}
              nftDetails={nftDetails}
              isOwner={props.isOwner}
              sortFilter={activeCollection}
              setItemNumber={setItemNumber}
              loading={props?.loading}
              flr={flr}
              sgb={sgb}

            />
          ) : activeLayout == "artwork" ? (
            <LayoutArtwork
              flag={Flag}
              nfts={props.nfts}
              ApvNFT={props.ApvNFT}
              nftDetails={nftDetails}
              isOwner={props.isOwner}
              sortFilter={activeCollection}
              setItemNumber={setItemNumber}
              loading={props?.loading}
              flr={flr}
              sgb={sgb}

            />
          ) : (
            <></>
          )}
        </div>
        {/* show those arts that matches username */}
      </div>

      {/* layout filter */}

      <Offcanvas
        show={mobileLayout}
        onHide={handleClose}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu ">
          <div className="menu-head">
            <label className="text-black">layout</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={handleClose}
              />
            </div>
          </div>

          <div className="share">
            <label
              className=" h-64 text-black"
              onClick={() => handleActiveLayout("grid")}
            >
              <div>
                <img src={grid} alt="grid" className="me-3 " />
                Grid Layout
              </div>

              <img
                src={tick}
                alt="tick"
                className={
                  activeLayout === "grid" ? "active opacity-100" : "opacity-0"
                }
              />
            </label>
            <label
              className=" h-64 text-black"
              onClick={() => handleActiveLayout("row")}
            >
              <div className="cursor-pointer">
                <img src={row} alt="row" className="me-3 " />
                Row Layout
              </div>
              <img
                src={tick}
                alt="tick"
                className={
                  activeLayout === "row" ? "active opacity-100" : "opacity-0"
                }
              />
            </label>
            <label
              className=" h-64 text-black"
              onClick={() => handleActiveLayout("artwork")}
            >
              <div className="cursor-pointer">
                <img src={artwork} alt="artwork" className="me-3 " />
                Artwork Layout
              </div>

              <img
                src={tick}
                alt="tick"
                className={
                  activeLayout === "artwork"
                    ? "active opacity-100"
                    : "opacity-0"
                }
              />
            </label>
          </div>
        </div>
      </Offcanvas>

      {/* mobile multi filter */}
      <Offcanvas
        show={showSideFilter}
        onHide={closeMobileSideFilter}
        placement="bottom"
        className="sub-menu-offcanvas collection-multi-filter"
      >
        <div className="more-menu-sm price-more-menu ">
          <div className="menu-head">
            <label htmlFor="">{itemNumber} Items</label>
            <label className="text-black multi-filter-head">Filters</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={closeMobileSideFilter}
              />
            </div>
          </div>

          <AsideFilter onSelectedFilterChange={handleSelectedFilterChange} />
        </div>
      </Offcanvas>

      {/* Collection Sorting Filter mobile menu */}
      <Offcanvas
        show={collectionMobileFilter}
        onHide={hideMobileSortFilter}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">sort by</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={hideMobileSortFilter}
              />
            </div>
          </div>

          <div className="share">
            {collectionSortFilter.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activeCollectionFilter === option.value
                  ? "fw-bold"
                  : "fw-normal"
                  }`}
                onClick={() => handleCollectionTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activeCollectionFilter === option.value
                    ? "active opacity-100"
                    : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default Collected;
