import React, { useEffect, useState, useContext } from "react";
import "../../style/main.scss";
import leftArrow from "../../assets/icon/chevron-left-small.svg";
import Notifications from "../notifications";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import UseWindowResize from "../../customHooks/useWindowResize";
import useScrollToTop from "../../customHooks/scrollToTop";
import { RefreshProvider } from "../notificationContext";
import config from "../../config";
import {
  getSalesAndWhitelistedStats,
  getAdminStats,
  getAllUsers,
} from "../../firebase/firebase";
import { RefreshContext } from "../notificationContext";
import { useAccount } from "wagmi";
import marketplaceContractABI from "../../abis/Marketplace/v2/abi.json";
import Web3 from "web3";

const Dashboard = () => {
  const [dateRange, setDateRange] = useState("All");
  const [metrics, setMetrics] = useState(dateRange);
  const [sale, setSale] = useState(0);

  const [whiteListedArtist, setWhiteListedArtist] = useState(0);
  const [volumeSGB, setVolumeSGB] = useState(0);
  const [volumeFLR, setVolumeFLR] = useState(0);
  const [volumeUSD, setVolumeUSD] = useState(0);
  const [commissionUSD, setCommissionUSD] = useState(0);
  const [commisionSGB, setCommissionSGB] = useState(0);
  const [commisionFLR, setCommissionFLR] = useState(0);

  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);


  // marketplace contract address

  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  const web3_coston = new Web3(config.rpc.songbird);
  const web3_coston2 = new Web3(config.rpc.rpc);

  const [whitelistCount, setWhiteListCount] = useState(null);
  const getUser = async () => {
    try {
      let res = await getAllUsers();
      const whitelistedUsers = res.filter((user) => user.isWhiteListed);
      console.log(whitelistedUsers, "whitelistedusers");
      setWhiteListCount(whitelistedUsers.length);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  const getSaleFromContracts = async () => {
    const contract1 = new web3_coston.eth.Contract(
      contractABI,
      Marketplace_coston_contractAddress
    );
    const contract2 = new web3_coston2.eth.Contract(
      contractABI,
      Marketplace_coston2_contractAddress
    );

    const currentTimestamp = Math.floor(Date.now() / 1000);
    let timestampAgo, timestampStart;
    let deployTime;
    console.log(contract1.methods, "deployTime");
    deployTime = await contract1.methods.deployTime().call();
    deployTime = await contract2.methods.deployTime().call();

    console.log(deployTime);

    console.log(dateRange, "date range---");
    switch (dateRange) {
      case "24h":
        timestampAgo = 24 * 60 * 60;
        break;
      case "7d":
        timestampAgo = 7 * 24 * 60 * 60;
        break;
      case "30d":
        timestampAgo = 30 * 24 * 60 * 60;
        break;
      case "All":
        timestampAgo = deployTime;
    }
    console.log(timestampAgo, "timestamagoooo");

    timestampStart = currentTimestamp - timestampAgo;
    console.log(timestampStart, "timestampStart");
    console.log(currentTimestamp, "currentTimestamp");

    try {
      // Fetch data for contract 1
      const [sales1, volumeSGB1, commission1] = await Promise.all([
        contract1.methods.Sale_W_R_T(timestampStart, currentTimestamp).call(),
        contract1.methods.Volume_W_R_T(timestampStart, currentTimestamp).call(),
        contract1.methods
          .Commission_W_R_T(timestampStart, currentTimestamp)
          .call(),
      ]);

      // Fetch data for contract 2
      const [sales2, volumeSGB2, commission2] = await Promise.all([
        contract2.methods.Sale_W_R_T(timestampStart, currentTimestamp).call(),
        contract2.methods.Volume_W_R_T(timestampStart, currentTimestamp).call(),
        contract2.methods
          .Commission_W_R_T(timestampStart, currentTimestamp)
          .call(),
      ]);

      // Calculate total sales and volumeSGB
      const totalSales = parseInt(sales1) + parseInt(sales2);
      const totalVolumeSGB = parseInt(volumeSGB1) + parseInt(volumeSGB2);

      // Set states based on the calculated values
      console.log(commission2, "commission2");
      setSale(totalSales);
      setVolumeSGB(parseInt(volumeSGB1) / 1000000000000000000);
      setVolumeFLR(parseInt(volumeSGB2) / 1000000000000000000);
      setCommissionSGB(parseInt(commission1) / 1000000000000000000);
      setCommissionFLR(parseInt(commission2) / 1000000000000000000);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSaleFromContracts();
  }, [dateRange]);

  const getSaleFromContracts2 = async () => {
    const contract = new web3_coston.eth.Contract(
      contractABI,
      Marketplace_coston_contractAddress
    );
    const contract2 = new web3_coston2.eth.Contract(
      contractABI,
      Marketplace_coston2_contractAddress
    );
    console.log("contract: ", contract.methods);
    console.log("contract2: ", contract2.methods);
  };

  useEffect(() => {
    getSaleFromContracts2();
  }, [dateRange]);

  const navigate = useNavigate();
  useEffect(() => {
    if (dateRange === "All") {
      setMetrics("All-time ");
    } else if (dateRange === "24h") {
      setMetrics("Last 24 hours  ");
    } else if (dateRange === "7d") {
      setMetrics("Last 7 days ");
    } else if (dateRange === "30d") {
      setMetrics("Last 30 days  ");
    }
  }, [dateRange]);

  useEffect(() => {
    getSalesAndWhitelisted();
    getStats();
  }, []);

  const getStats = async () => {
    const stats = await getAdminStats();
    console.log("stats: ", stats);
  };

  const getSalesAndWhitelisted = async () => {
    const stats = await getSalesAndWhitelistedStats();
    console.log("stats: ", stats);
    // setSale(stats?.sales);
    setWhiteListedArtist(stats?.whitelistedArtists);
  };

  const handleTabClick = (tab) => {
    setDateRange(tab);
  };
  const state = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
  };

  UseWindowResize(1024, "/catalyst-phoenix-nexus");
  useScrollToTop();
  return (
    <div>
      <div className="admin-content-head  v-center justify-content-between flex-row header-fixed ">
        {/* Header for desktop */}
        <h3 className="fw-bold text-capitalize for-desktop">Dashboard</h3>
        {/* Header for mobile */}
        <h6
          className="fw-bold text-capitalize for-mobile"
          onClick={() => navigate(-1)}
        >
          <img src={leftArrow} alt="back" className="me-3" />
          Dashboard
        </h6>

        {/* Toggle between date range */}
        <div className="white-labels v-center gap-2 hide-on-mobile">
          <label
            className={` ${dateRange === "24h" ? "active" : ""}`}
            onClick={() => handleTabClick("24h")}
          >
            24h
          </label>
          <label
            className={` ${dateRange === "7d" ? "active" : ""}`}
            onClick={() => handleTabClick("7d")}
          >
            7d
          </label>
          <label
            className={` ${dateRange === "30d" ? "active" : ""}`}
            onClick={() => handleTabClick("30d")}
          >
            30d
          </label>
          <label
            className={` ${dateRange === "All" ? "active" : ""}`}
            onClick={() => handleTabClick("All")}
          >
            All
          </label>
        </div>

        <div className="date-range-mb hide-on-desktop">
          <Dropdown>
            <Dropdown.Toggle>
              <p className="body-large">{dateRange}</p>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleTabClick("24h")}>
                <p className="body-large">24h</p>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleTabClick("7d")}>
                <p className="body-large">7d</p>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleTabClick("30d")}>
                <p className="body-large">30d</p>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleTabClick("All")}>
                <p className="body-large">All</p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="dashboard-wrapper">
        <div className="toggle-spotlight">
          <p className="body-large fw-bold mt-5 pt-2">
            Overview Metrics
            <span className="fw-normal ms-3 ps-1">{metrics}</span>
          </p>
        </div>

        <div className="data-boxes mt-36">
          <div className="data-box">
            <label className="fw-bold text-black">{sale}</label>
            <p className="body-small fw-bold  pt-2 text-medium-grey">Sales</p>
          </div>

          <div className="data-box">
            <label className="fw-bold text-black">{volumeSGB}</label>

            <p className="body-small fw-bold  pt-2 text-medium-grey">
              Volume (SGB)
            </p>
          </div>

          <div className="data-box">
            <label className="fw-bold text-black">{volumeFLR}</label>
            <p className="body-small fw-bold  pt-2 text-medium-grey">
              Volume (FLR)
            </p>
          </div>

          {/* <div className="data-box">
            <label className="fw-bold text-black">$104,022</label>
            <p className="body-small fw-bold  pt-2 text-medium-grey">
              Volume (USD)
            </p>
          </div> */}
          <div className="data-box">
            <label className="fw-bold text-black">{commisionSGB}</label>
            <p className="body-small fw-bold  pt-2 text-medium-grey">
              Commission (SGB)
            </p>
          </div>
          <div className="data-box">
            <label className="fw-bold text-black">{commisionFLR}</label>
            <p className="body-small fw-bold  pt-2 text-medium-grey">
              Commission (FLR)
            </p>
          </div>

          <div className="data-box">
            <label className="fw-bold text-black">{whitelistCount}</label>
            <p className="body-small fw-bold  pt-2 text-medium-grey">
              Whitelisted Artists
            </p>
          </div>
        </div>

        <div className="volume">
          <p className="body-large fw-bold">Volume</p>
          <div id="chart_div" className="overflow-hidden">
            <Chart
              options={state.options}
              series={state.series}
              type="line"
              className="volume-chart"
            />
          </div>
        </div>

        <div className="notification">
          <RefreshProvider>
            <Notifications
              activeClass="active"
              hide="hide-content"
              noBorder="border-0"
              type="admin"
            />
          </RefreshProvider>

        </div>
      </div>
    </div>
  );
};

export default Dashboard;
