import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import sgb from "../../assets/icon/SGB.svg";
import vidIcon from "../../assets/icon/video-fill.svg";
import flr from "../../assets/icon/FLR.svg";
import profile from "../../assets/icon/profile-picture.svg";
import loader from "../../assets/icon/loader-medium.svg";
import userProfile from "../../assets/icon/profile-picture.svg";
import AsideFilterExplore from "./asideFilterExplore";
import ArtistPopUp from "../shared/artistpopup";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { get, off, set } from "firebase/database";
import { getAllUsers, getCollectionByAddress } from "../../firebase/firebase";
import Web3 from "web3";
import marketplaceContractABI from "../../abis/Marketplace/abi.json";
import mintContractABI from "../../abis/SafeMint/abi.json";
import { useAccount } from "wagmi";
import CardSkelton from "../shared/cardSkelton";
import { Popover } from "antd";
import config from "../../config";

const LayoutExploreGrid = (props) => {
  console.log(props, "props");
  const web3 = new Web3();
  const [collectedArts, setCollectedArts] = useState([]);
  const [nftData, setNftData] = useState(null);
  const [offerNftData, setOfferNftData] = useState(null);
  const { address } = useAccount();
  const [userName, setUserName] = useState(null);
  const [load, setLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const handleLoad = () => {
    setLoad(true);
  };
  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  // marketplace contract address
  const fetchDataAndUpdateDetails = async () => {
    try {
      console.log(props?.listedNft, "listed nftsssssssssssz");

      let bidding;
      if (props?.listedNft) {
        let data = props?.listedNft.flat();
        console.log(data, "dsdat#################");
        try {
          const web3 = new Web3(window.ethereum);
          const updatedDetails = await Promise.all(
            data
              .map(async (item) => {
                console.log(item, "item filter");
                const MarketplaceAddress =
                  item?.metadata.data.selectedBlockchain === "Coston"
                    ? Marketplace_coston_contractAddress
                    : Marketplace_coston2_contractAddress;
                console.log(item?.metadata.data.selectedBlockchain);
                const contract = new web3.eth.Contract(
                  marketplaceContractABI,
                  MarketplaceAddress
                );
                console.log(contract, "contract");
                try {
                  console.log(item?.data.offerCountIndex, "list count");
                  const biddingHistoryCoston = await contract.methods
                    .getBiddingHistory(item?.data.offerCountIndex)
                    .call();
                  console.log(biddingHistoryCoston, "biddinghistoty");

                  let maxPrice = null;
                  if (biddingHistoryCoston && biddingHistoryCoston.length > 0) {
                    maxPrice = calculateMaxPrice(biddingHistoryCoston);
                    console.log(maxPrice, "maxPrice");
                  }

                  return {
                    ...item,
                    maxPrice: maxPrice ? maxPrice / 1e18 : null,
                  }; // Converting to ether or setting to null
                } catch (error) {
                  console.error("Error fetching bidding history:", error);
                  return { ...item, maxPrice: null }; // Handle error fetching bidding history by returning the item with null maxPrice
                }
              })
          );

          setNftData((prevDetails) => {
            const unchangedItems = prevDetails?.filter(
              (prevItem) => !prevItem?.data.isOfferListed
            );
            if (unchangedItems) {
              console.log(unchangedItems, "unchanged items");
              console.log(updatedDetails, "updated Details");
              const mergedDetails = [
                ...unchangedItems,
                ...updatedDetails.filter(Boolean),
              ];
              console.log(mergedDetails, "merged details");
              return mergedDetails;
            }
          });
        } catch (error) {
          console.error("Error fetching data and updating details:", error);
          // Handle error fetching data and updating details
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      console.log(biddingHistory, "testing bidding history layout explore grid");
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (!isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };
  useEffect(() => {
    if (props?.listedNft) {
      setCollectedArts(props?.listedNft);
      const fetchdata = async () => {
        await fetchDataAndUpdateDetails();
      };
      fetchdata();
    }
  }, [props?.listedNft]);

  // get username from local storage

  useEffect(() => {
    let name = localStorage.getItem("userName");
    setUserName(name);
  }, [localStorage.getItem("userName")]);

  useEffect(() => {
    if (
      (props?.listedNft && props?.listedNft.length < 0) ||
      !props?.listedNft
    ) {
      // props?.setLoading(false);
    }
  }, [props]);

  // set props data to state
  useEffect(() => {
    setIsLoading(true);
    if (props?.listedNft) {
      let filteredNftData = props?.listedNft;
      let filteredOfferNftData = props?.listedNft?.offerNftData;
      let nft = [];
      let offer = [];

      console.log(props?.tagName);
      console.log(filteredNftData);
      if (filteredNftData) {
        for (let a of filteredNftData) {
          let tagsArray = a?.meta?.data?.selectedTags;
          if (tagsArray?.includes(props?.tagName)) {
            console.log(a);
            nft.push(a);
          }
          console.log(a?.meta?.data?.selectedTags, "tags array");
        }
      }
      if (filteredOfferNftData) {
        for (let a of filteredOfferNftData) {
          let tagsArray = a?.meta?.data?.selectedTags;
          if (tagsArray?.includes(props?.tagName)) {
            console.log(a);
            offer.push(a);
          }
          console.log(a?.meta?.data?.selectedTags, "tags array");
        }
      }

      if (props?.tagName) {
        setNftData(nft);
        setOfferNftData(offer);
        props?.setItemsNumber(nft?.length + offer.length);
        setIsLoading(false);
      } else {
        setNftData(filteredNftData);
        setOfferNftData(filteredOfferNftData);
        setIsLoading(false);
      }
      // Update state with filtered or unfiltered NFTs
      setCollectedArts(props.listedNft);
    }
  }, [props.tagName, props.listedNft]);

  const navigate = useNavigate();

  const getUserNftsFromContract = async (item) => {
    try {
      console.log(item, "item in tokee");
      console.log(item.uri);
      let res = await getCollectionByAddress(item?.metadata.data.artistAddress);
      console.log(res, "response");
      console.log(item?.metadata.data.selectedCollectionId);
      let collectionDetails = res.filter((e) => {
        return e.documentId === item?.metadata.data.selectedCollectionId;
      });
      console.log(collectionDetails, "collection details");

      console.log(collectionDetails[0]?.contractAddress);

      if (collectionDetails[0]?.contractAddress) {
        let web3_1;
        if (item?.metadata.data.selectedBlockchain === "Coston") {
          web3_1 = new Web3(config.rpc.songbird);
        } else if (item?.metadata.data.selectedBlockchain === "Coston2") {
          web3_1 = new Web3(config.rpc.flr);
        }
        console.log(web3_1);
        console.log(item?.metadata.data.selectedBlockchain);

        try {
          if (web3_1 !== null) {
            const contract = new web3_1.eth.Contract(
              mintContractABI,
              collectionDetails[0]?.contractAddress
            );
            const contractOwner = await contract.methods.owner().call();

            return new Promise((resolve, reject) => {
              contract.methods
                .getTokenId(collectionDetails[0]?.address)
                .call({ from: contractOwner }, async (error, result) => {
                  if (error) {
                    console.error(error);
                    reject(error);
                  } else {
                    console.log(result, "result");
                    if (result?.length > 0) {
                      for (let a of result) {
                        if (a.uri === item.data.uri) {
                          console.log(a.tokenId);
                          navigate(
                            `/artwork/${item?.metadata.data.selectedBlockchain}/${item?.metadata.data.selectedCollectionId}/${a?.tokenId}`
                          );
                          resolve(a.tokenId);
                        }
                      }
                    } else {
                      console.log("error");
                      reject("Error: No result found");
                    }
                  }
                });
            });
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // get nfts token for nfts that are not listed

  const navigateTo = (item, listType) => {
    if (item?.data?.isListed === false) {
      getUserNftsFromContract(item);
      return;
    }
    let collectionId = item?.metadata.data.selectedCollectionId;
    let tokenId = item?.data.listedData.tokenId;
    let network = item.metadata?.data?.selectedBlockchain;

    navigate(`/artwork/${network}/${collectionId}/${tokenId}/`);
  };

  const tooltip1 = (artPrice) => {
    return <Tooltip id="tooltip1">{artPrice}</Tooltip>;
  };

  const tooltip2 = (chainName) => {
    return <Tooltip id="tooltip2">Chain: {chainName}</Tooltip>;
  };

  const checkUser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserData(user);
    }
  };

  useEffect(() => {
    checkUser();
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  const getArtistNamebyAdress = (accountAddress) => {
    console.log(accountAddress, "account address");
    console.log(userData, "user data");
    let artistName = "";

    if (address === accountAddress) {
      return "You";
    } else {
      if (!userData) {
        return;
      }
      const user = userData?.find((user) => user?.id === accountAddress);
      if (user) {
        artistName = user?.userName;
      }
    }

    return "@" + artistName;
  };

  const checkIfWhiteListed = (accountAddress) => {
    const user = userData.find((user) => user?.id === accountAddress);
    console.log(user?.isWhiteListed);
    return user?.isWhiteListed || false;
  };
  const getArtistImage = (accountAddress) => {
    let artistImage = "";

    // Assuming userData is fetched asynchronously
    try {
      const users = userData;
      const user = users.find((user) => user?.id === accountAddress);
      if (user?.image) {
        return user.image;
      }
      if (!user?.image) {
        return userProfile;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error, maybe return a default image
    }

    return artistImage;
  };

  // Function to convert price from wei to ether
  const convertWeiToEther = (priceInWei) => {
    // Convert the price from wei to ether
    const priceInEther = web3.utils.fromWei(priceInWei?.toString(), "ether");
    return priceInEther;
  };

  useEffect(() => {
    console.log(props?.loading);

    console.log(props?.loading, "nft data in layout explore grid");
  }, [props?.loading]);
  useEffect(() => {
    console.log(nftData);
    console.log(offerNftData);
  }, [nftData, offerNftData]);

  return (
    <div>
      <div
        className={
          props.flag ? "d-flex  justify-content-between align-items-start" : ""
        }
      >
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          <AsideFilterExplore
            onSelectedFilterChange={props.onSelectedFilterChange}
          />
        </div>

        {props?.loading ? (
          <div
            className={`grid-display artworkGrid gap-4  ${props.flag ? "w-75 active" : ""
              }`}
          >
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
          </div>
        ) : (
          <div
            className={`grid-display artworkGrid  ${props.flag ? "w-75 active" : ""
              }`}
          >
            {props?.listedNft?.length < 0 && props?.listedNft !== null ? (
              <div className="no-content mt-5 pt-5">
                <p className="body-large">
                  <span>No Artwork Found</span>
                </p>
              </div>
            ) : (
              <>
                {nftData &&
                  nftData.map((item, index) => {
                    console.log(item?.data?.newOwner, "item data");
                    if (item?.data?.isListed === true || item === undefined) {
                      return (
                        <div className="collection-grid-card" key={index}>
                          <div className="card-head  ">
                            <div className="user-img">
                              {item && (
                                <>
                                  {/* <p>   getArtistImage(item?.metadata?.data?.seller)</p> */}
                                  <img
                                    src={getArtistImage(item.data?.newOwner)}
                                    className="img-100"
                                  />
                                </>
                              )}
                              {!item && (
                                <>
                                  <img src={userProfile} className="img-100" />
                                </>
                              )}
                            </div>

                            <div className="user-name">
                              <p
                                onClick={() => {
                                  navigateTo(item);
                                }}
                                className="body-large hover-underline pointer"
                              >
                                {item?.metadata?.data?.artName?.length > 18 ? (
                                  <>
                                    {item?.metadata?.data?.artName.substring(
                                      0,
                                      18
                                    ) || "Art Name"}
                                  </>
                                ) : (
                                  <>
                                    {item?.metadata?.data?.artName ||
                                      "Art Name"}
                                  </>
                                )}
                              </p>

                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <>
                                    {address && (
                                      <ArtistPopUp
                                        userProfile={getArtistImage(
                                          item?.data.newOwner ||
                                          item?.data?.owner
                                        )}
                                        verified={verified}
                                        artistName={getArtistNamebyAdress(
                                          item?.data.newOwner ||
                                          item?.data?.owner
                                        )}
                                      />
                                    )}
                                  </>
                                }
                              >
                                <p
                                  onClick={() => {
                                    navigate(
                                      `/profile/${item?.data?.newOwner || item?.data?.owner
                                      }`
                                    );
                                  }}
                                  className="fw-bold text-medium-grey hoverBlack "
                                >
                                  {getArtistNamebyAdress(
                                    item?.data?.newOwner || item?.data?.owner
                                  ).length > 14
                                    ? getArtistNamebyAdress(
                                      item?.data?.newOwner || item?.data?.owner
                                    ).substring(0, 14) + "..."
                                    : getArtistNamebyAdress(
                                      item?.data?.newOwner || item?.data?.owner
                                    )}

                                  {checkIfWhiteListed(item?.data?.newOwner) && (
                                    <img
                                      src={verified}
                                      alt="verified"
                                      className="img-18 ms-1"
                                    />
                                  )}
                                </p>
                              </Popover>
                            </div>
                          </div>

                          <div className="card-body ">
                            <div
                              className="art-img"
                              onClick={() => navigateTo(item, "listed")}
                            >
                              {item?.metadata?.data?.image && (
                                <>
                                  {!load && (
                                    <div className="content-loader rotate-360 d-none">
                                      <img src={loader} alt="loader" />
                                    </div>
                                  )}
                                  <img
                                    src={item?.metadata?.data?.image}
                                    alt="art"
                                    className={`img-100 artwork-hover  ${!load ? "hidden" : ""
                                      }`}
                                    onLoad={handleLoad}
                                  />
                                </>
                              )}

                              {!item?.metadata?.data?.image &&
                                item?.metadata?.data?.previewImg && (
                                  <>
                                    {!load && (
                                      <div className="content-loader rotate-360 d-none">
                                        <img src={loader} alt="loader" />
                                      </div>
                                    )}
                                    <img
                                      src={item?.metadata?.data?.previewImg}
                                      alt="Preview"
                                      className={`img-100 artwork-hover ${!load ? "hidden" : ""
                                        }`}
                                      onLoad={handleLoad}
                                    />
                                  </>
                                )}
                            </div>
                            {item?.metadata?.data?.selectedBlockchain ===
                              "Coston" ? (
                              <>
                                <div className="chain-logo ">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip2(
                                      item?.metadata?.data?.selectedBlockchain
                                    )}
                                    id="tooltip1"
                                  >
                                    <img src={sgb} alt="chain logo" />
                                  </OverlayTrigger>
                                </div>

                                {item?.data?.listedData?.price && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip1(
                                      item?.data.listedData?.price /
                                      1000000000000000000
                                    )}
                                    id="tooltip1"
                                  >
                                    <div className="sgb">
                                      <img src={sgb} alt="sgb" />
                                      <p className="body-large text-white ms-1">
                                        {item?.data.listedData?.price?.length /
                                          1000000000000000000 >
                                          3 ? (
                                          <>
                                            {item?.data.listedData?.price /
                                              1000000000000000000 /
                                              10 +
                                              "K"}
                                          </>
                                        ) : (
                                          <>
                                            {item?.data.listedData?.price /
                                              1000000000000000000}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </OverlayTrigger>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="chain-logo ">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip2(
                                      item?.metadata?.data?.selectedBlockchain
                                    )}
                                    id="tooltip1"
                                  >
                                    <img src={flr} alt="chain logo" />
                                  </OverlayTrigger>
                                </div>

                                {item?.data.listedData?.price && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip1(
                                      item?.data.listedData?.price /
                                      1000000000000000000
                                    )}
                                    id="tooltip1"
                                  >
                                    <div className="sgb">
                                      <img src={flr} alt="flr" />
                                      <p className="body-large text-white ms-1">
                                        {item?.data.listedData?.price?.length /
                                          1000000000000000000 >
                                          3 ? (
                                          <>
                                            {item?.data.listedData?.price /
                                              1000000000000000000 /
                                              10 +
                                              "K"}
                                          </>
                                        ) : (
                                          <>
                                            {item?.data.listedData?.price /
                                              1000000000000000000}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </OverlayTrigger>
                                )}
                              </>
                            )}
                            {item?.metadata?.data?.previewImg && (
                              <div className="video-icon  position-absolute   ">
                                <img src={vidIcon} alt="" />
                              </div>
                            )}
                          </div>

                          <div className="card-footer">
                            <Popover
                              placement="top"
                              trigger="hover"
                              content={
                                <>
                                  {
                                    <ArtistPopUp
                                      userProfile={getArtistImage(
                                        item?.data.newOwner
                                      )}
                                      verified={verified}
                                      artistName={getArtistNamebyAdress(
                                        item?.data.newOwner
                                      )} // passing artist as prop
                                    />
                                  }
                                </>
                              }
                            >
                              <div className="owner">
                                <p className="body-medium text-medium-grey ">
                                  Owner
                                </p>
                                <label
                                  onClick={() => {
                                    navigate(`/profile/${item?.data.newOwner}`);
                                  }}
                                  className="medium text-black no-text-transform pointer"
                                >
                                  <img src={profile} alt="profile" />

                                  {getArtistNamebyAdress(item?.data.newOwner)
                                    .length > 14
                                    ? getArtistNamebyAdress(
                                      item?.data.newOwner
                                    ).substring(0, 14) + "..."
                                    : getArtistNamebyAdress(item?.data.newOwner)}
                                </label>
                              </div>
                            </Popover>

                            {item?.metadata?.data.lastPrice ? (
                              <div className="offer">
                                <p className="body-medium text-medium-grey ">
                                  Last Sale Price
                                </p>
                                <label className="medium text-black">
                                  {item?.metadata?.data.lastPrice /
                                    1000000000000000000 || item?.maxPrice}
                                </label>
                              </div>
                            ) : (
                              <div className="offer">
                                <p className="body-medium text-medium-grey ">
                                  Best Offer
                                </p>
                                <label className="medium text-black">
                                  {item?.maxPrice || "--"}
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="collection-grid-card" key={index}>
                          <div className="card-head  ">
                            <div className="user-img">
                              {item && (
                                <>
                                  {/* <p>   getArtistImage(item?.metadata?.data?.seller)</p> */}
                                  <img
                                    src={getArtistImage(
                                      item?.metadata?.data?.artistAddress
                                    )}
                                    className="img-100"
                                  />
                                </>
                              )}
                              {!item && (
                                <>
                                  <img src={userProfile} className="img-100" />
                                </>
                              )}
                            </div>

                            <div className="user-name">
                              <p
                                onClick={() => {
                                  navigateTo(item);
                                }}
                                className="body-large hover-underline pointer"
                              >
                                {item?.metadata?.data?.artName?.length > 18
                                  ? `${item.metadata.data.artName.substring(
                                    0,
                                    18
                                  )}...`
                                  : item?.metadata?.data?.artName || "Art Name"}
                              </p>

                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <>
                                    {
                                      <ArtistPopUp
                                        userProfile={getArtistImage(
                                          item?.metadata?.data?.artistAddress
                                        )}
                                        verified={verified}
                                        artistName={getArtistNamebyAdress(
                                          item?.metadata?.data?.artistAddress
                                        )}
                                      />
                                    }
                                  </>
                                }
                              >
                                <p
                                  onClick={() => {
                                    navigate(
                                      `/profile/${item?.metadata?.data?.artistAddress}`
                                    );
                                  }}
                                  className="fw-bold text-medium-grey hoverBlack "
                                >
                                  {getArtistNamebyAdress(
                                    item?.metadata?.data?.artistAddress
                                  ).length > 18
                                    ? getArtistNamebyAdress(
                                      item?.metadata?.data?.artistAddress
                                    ).substring(0, 18) + "..."
                                    : getArtistNamebyAdress(
                                      item?.metadata?.data?.artistAddress
                                    )}
                                  <img
                                    src={verified}
                                    alt="verified"
                                    className="img-18 ms-1"
                                  />
                                </p>
                              </Popover>
                            </div>
                          </div>

                          <div className="card-body ">
                            <div
                              className="art-img"
                              onClick={() => navigateTo(item, "listed")}
                            >
                              {item?.metadata?.data?.image && (
                                <>
                                  {!load && (
                                    <div className="content-loader rotate-360 d-none">
                                      <img src={loader} alt="loader" />
                                    </div>
                                  )}
                                  <img
                                    src={item?.metadata?.data?.image}
                                    alt="art"
                                    className={`img-100 artwork-hover  ${!load ? "hidden" : ""
                                      }`}
                                    onLoad={handleLoad}
                                  />
                                </>
                              )}

                              {!item?.metadata?.data?.image &&
                                item?.metadata?.data?.previewImg && (
                                  <>
                                    {!load && (
                                      <div className="content-loader rotate-360 d-none">
                                        <img src={loader} alt="loader" />
                                      </div>
                                    )}
                                    <img
                                      src={item?.metadata?.data?.previewImg}
                                      alt="Preview"
                                      className={`img-100 artwork-hover ${!load ? "hidden" : ""
                                        }`}
                                      onLoad={handleLoad}
                                    />
                                  </>
                                )}
                            </div>
                            {item.meta?.data?.selectedBlockchain ===
                              "Coston" ? (
                              <>
                                <div className="chain-logo ">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip2(
                                      item.meta?.data?.selectedBlockchain
                                    )}
                                    id="tooltip1"
                                  >
                                    <img src={sgb} alt="chain logo" />
                                  </OverlayTrigger>
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip1(item?.listedData?.price)}
                                  id="tooltip1"
                                >
                                  <div className="sgb">
                                    <img src={sgb} alt="sgb" />
                                    <p className="body-large text-white ms-1">
                                      {item?.listedData?.price.length > 3 ? (
                                        <>
                                          {item?.listedData?.price / 10 + "K"}
                                        </>
                                      ) : (
                                        <>{item?.listedData?.price}</>
                                      )}
                                    </p>
                                  </div>
                                </OverlayTrigger>
                              </>
                            ) : (
                              <>
                                {/* <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(
                                  item.meta?.data?.selectedBlockchain
                                )}
                                id="tooltip1"
                              >
                                <img src={flr} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip1(
                                (item?.listedData?.price)
                              )}
                              id="tooltip1"
                            >
                              <div className="sgb">
                                <img src={flr} alt="flr" />
                                <p className="body-large text-white ms-1">
                                  {(
                                    item?.listedData?.price.length
                                  ) > 3 ? (
                                    <>
                                      {(
                                        item?.listedData?.price
                                      ) /
                                        10 +
                                        "K"}
                                    </>
                                  ) : (
                                    <>
                                      {(
                                        item?.listedData?.price
                                      )}
                                    </>
                                  )}
                                </p>
                              </div>
                            </OverlayTrigger> */}
                              </>
                            )}
                            {item?.metadata?.data?.previewImg && (
                              <div className="video-icon  position-absolute   ">
                                <img src={vidIcon} alt="" />
                              </div>
                            )}
                          </div>

                          <div className="card-footer">
                            <Popover
                              placement="top"
                              trigger="hover"
                              content={
                                <>
                                  {
                                    <ArtistPopUp
                                      userProfile={getArtistImage(
                                        item?.metadata?.data?.artistAddress
                                      )}
                                      verified={verified}
                                      artistName={getArtistNamebyAdress(
                                        item?.metadata?.data?.artistAddress
                                      )} // passing artist as prop
                                    />
                                  }
                                </>
                              }
                            >
                              <div className="owner">
                                <p className="body-medium text-medium-grey ">
                                  Owner
                                </p>
                                <label
                                  onClick={() => {
                                    navigate(
                                      `/profile/${item?.metadata?.data?.artistAddress}`
                                    );
                                  }}
                                  className="medium text-black no-text-transform"
                                >
                                  <img src={profile} alt="profile" />

                                  {getArtistNamebyAdress(
                                    item?.metadata?.data?.artistAddress
                                  ).length > 14
                                    ? getArtistNamebyAdress(
                                      item?.metadata?.data?.artistAddress
                                    ).substring(0, 14) + "..."
                                    : getArtistNamebyAdress(
                                      item?.metadata?.data?.artistAddress
                                    )}
                                </label>
                              </div>
                            </Popover>

                            {/* <div className="offer">
                          <p className="body-medium text-medium-grey ">
                            Last Sale Price
                          </p>
                          <label className="medium text-black">
                            {item?.meta?.data.lastPrice / 100000000000000000000 ||
                              "--"}
                          </label>
                        </div> */}
                          </div>
                        </div>
                      );
                    }
                  })}
                {offerNftData &&
                  offerNftData.map((item, index) => {
                    if (
                      item?.listedData?.listed === false ||
                      item === undefined
                    ) {
                      return <></>;
                    } else {
                      return (
                        <div className="collection-grid-card" key={index}>
                          <div className="card-head  ">
                            <div className="user-img">
                              <img
                                src={
                                  getArtistImage(item?.data.owner) ||
                                  userProfile
                                }
                                className="img-100"
                              />
                            </div>

                            <div className="user-name">
                              <p
                                onClick={() => {
                                  navigateTo(item);
                                }}
                                className="body-large hover-underline pointer"
                              >
                                {item?.metadata?.data?.artName || "Art Name"}
                              </p>

                              <Popover
                                placement="top"
                                trigger="hover"
                                content={
                                  <>
                                    {address && (
                                      <ArtistPopUp
                                        userProfile={getArtistImage(
                                          item?.data.owner
                                        )}
                                        verified={verified}
                                        artistName={getArtistNamebyAdress(
                                          item?.data.owner
                                        )} // passing artist as prop
                                      />
                                    )}
                                  </>
                                }
                              >
                                <p
                                  onClick={() => {
                                    navigate(`/profile/${item?.data?.owner}`);
                                  }}
                                  className="fw-bold text-medium-grey hoverBlack   "
                                >
                                  @{getArtistNamebyAdress(item?.data.owner)}
                                  <img
                                    src={verified}
                                    alt="verified"
                                    className="img-18 ms-1"
                                  />
                                  {/* artsit pop up here */}
                                </p>
                              </Popover>
                            </div>
                          </div>

                          <div className="card-body">
                            <div
                              className="art-img"
                              onClick={() => navigateTo(item, "listed")}
                            >
                              {item?.metadata?.data?.image && (
                                <>
                                  {!load && (
                                    <div className="content-loader rotate-360 d-none">
                                      <img src={loader} alt="loader" />
                                    </div>
                                  )}
                                  <img
                                    src={item?.metadata?.data?.image}
                                    alt="art"
                                    className={`img-100 artwork-hover  ${!load ? "hidden" : ""
                                      }`}
                                    onLoad={handleLoad}
                                  />
                                </>
                              )}
                              {!item?.metadata?.data?.image &&
                                item?.metadata?.data?.previewImg && (
                                  <>
                                    {!load && (
                                      <div className="content-loader rotate-360 d-none">
                                        <img src={loader} alt="loader" />
                                      </div>
                                    )}
                                    <img
                                      src={item?.metadata?.data?.previewImg}
                                      alt="Preview"
                                      className={`img-100 artwork-hover ${!load ? "hidden" : ""
                                        }`}
                                      onLoad={handleLoad}
                                    />
                                  </>
                                )}
                            </div>

                            {/* <div className="chain-logo ">
                        <img src={sgb} alt="chain logo" />
                      </div> */}
                            {item.meta?.data?.selectedBlockchain ===
                              "Coston" ? (
                              <>
                                <div className="chain-logo ">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip2(
                                      item.meta?.data?.selectedBlockchain
                                    )}
                                    id="tooltip1"
                                  >
                                    <img src={sgb} alt="chain logo" />
                                  </OverlayTrigger>
                                </div>
                                <div className="chain-logo ">
                                  <img src={sgb} alt="chain logo" />
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip1(
                                    item?.listedData?.minimumBid
                                  )}
                                  id="tooltip1"
                                >
                                  <div className="sgb">
                                    <img src={sgb} alt="sgb" />
                                    <p className="body-large text-white ms-1">
                                      {item?.listedData?.minimumBid.length >
                                        3 ? (
                                        <>
                                          {item?.listedData?.minimumBid / 10 +
                                            "K"}
                                        </>
                                      ) : (
                                        <>{item?.listedData?.minimumBid}</>
                                      )}
                                    </p>
                                  </div>
                                </OverlayTrigger>
                              </>
                            ) : (
                              <>
                                <div className="chain-logo ">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip2(
                                      item.meta?.data?.selectedBlockchain
                                    )}
                                    id="tooltip1"
                                  >
                                    <img src={flr} alt="chain logo" />
                                  </OverlayTrigger>
                                </div>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip1(
                                    item?.listedData?.minimumBid
                                  )}
                                  id="tooltip1"
                                >
                                  <div className="sgb">
                                    <img src={flr} alt="flr" />
                                    <p className="body-large text-white ms-1">
                                      {item?.listedData?.minimumBid.length >
                                        3 ? (
                                        <>
                                          {item?.listedData?.minimumBid / 10 +
                                            "K"}
                                        </>
                                      ) : (
                                        <>{item?.listedData?.minimumBid}</>
                                      )}
                                    </p>
                                  </div>
                                </OverlayTrigger>
                              </>
                            )}

                            {item?.metadata?.data?.previewImg && (
                              <div className="video-icon  position-absolute   ">
                                <img src={vidIcon} alt="" />
                              </div>
                            )}
                          </div>

                          <div className="card-footer">
                            <Popover
                              placement="top"
                              trigger="hover"
                              content={
                                <>
                                  {address && (
                                    <ArtistPopUp
                                      userProfile={getArtistImage(
                                        item?.metadata?.data?.artistAddress
                                      )}
                                      verified={verified}
                                      artistName={
                                        getArtistNamebyAdress(
                                          item?.data?.owner
                                        ) || "OwnerName"
                                      } // passing artist as prop
                                    />
                                  )}
                                </>
                              }
                            >
                              <div className="owner">
                                <p className="body-medium text-medium-grey ">
                                  Owner
                                </p>
                                <label
                                  onClick={() => {
                                    navigate(`profile/${item?.data?.owner}`);
                                  }}
                                  className="medium text-black no-text-transform"
                                >
                                  <img src={profile} alt="profile" />@
                                  {getArtistNamebyAdress(item?.data.owner)}
                                  {/* artsit pop up here */}
                                </label>
                              </div>
                            </Popover>

                            <div className="offer">
                              <p className="body-medium text-medium-grey ">
                                Best Offer
                              </p>
                              <label className="medium text-black">
                                {item?.maxPrice / 100000000000000000000 || "--"}
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </>
            )}
          </div>
        )}
      </div>

      <div className="content-loader rotate-360 d-none">
        <img src={loader} alt="loader" />
      </div>
    </div>
  );
};

export default LayoutExploreGrid;
