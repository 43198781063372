import React, { useState, useRef, useEffect } from "react";
import "../../style/main.scss";

import { Tooltip } from "react-bootstrap";
import AsideFilter from "./asideFilter";

import { useNavigate } from "react-router-dom";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import Web3 from "web3";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import { useAccount } from "wagmi";
import config from "../../config";
import AsideFilterExplore from "../explore/asideFilterExplore";
export default function UserCollectedArtwork(props) {
  const { address } = useAccount();

  const [show, setShow] = useState({});
  const [activePrice, setActivePrice] = useState("listPrice");
  const [priceShow, setPriceShow] = useState(false);
  const dropdownRef = useRef(null);

  const [collectionDetails, setCollectionDetails] = useState(props?.ApvNFT);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState(null);
  const [tokenId, setTokenId] = useState();

  const [web3, setWeb3] = useState(new Web3(window.ethereum));

  useEffect(() => {
    setNftFilteredDetails(props?.listedNft);
  }, [props]);

  // setting rpc url
  useEffect(() => {
    if (collectionDetails?.selectedNetwork === config.networks.songbird) {
      setWeb3(new Web3(config.rpc.songbird));
    } else {
      setWeb3(new Web3(config.rpc.flr));
    }
  }, [collectionDetails]);

  // toolip
  const tooltip = <Tooltip id="tooltip">List price: 4900929 FLR</Tooltip>;
  const tooltipLSP = (
    <Tooltip id="tooltipLSP">Last sale price: 576 FLR</Tooltip>
  );
  const tooltipBO = <Tooltip id="tooltipBO">Best offer: 9760929 FLR</Tooltip>;

  // price Filter handle
  const priceHandleShow = () => setPriceShow(true);
  const priceHandleHide = () => setPriceShow(false);

  const showOption = (index) => {
    setShow({ [index]: true });
    console.log(index);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  // ----------- Handle option menu end

  const handleIPriceTick = (tick) => {
    setActivePrice(tick);
  };

  const priceOptions = [
    { value: "listPrice", label: "List price" },
    { value: "lastPrice", label: "Last sale price" },
    { value: "bestOffer", label: "Best offer" },
  ];

  // side filters

  const handleSelectedFilterChange = (newFilter) => {
    let filteredData = [];
    collectionData.filter((item) => {
      console.log("filter item", item);
      // Filter by categories
      // Check if any category is selected
      const isCategorySelected = Object.values(newFilter.selectCategories).some(
        (category) => category
      );
      let categoriesFilter;
      if (isCategorySelected) {
        //console.log("true");
        categoriesFilter = Object.keys(newFilter.selectCategories).every(
          (category) =>
            newFilter.selectCategories[category]
              ? item.data.selectedTags.includes(category)
              : true
        );
      } else {
        console.log("false");
        categoriesFilter = true;
      }

      // Filter by currency
      const selectedCurrency = Object.values(newFilter.selectCurrency).some(
        (currency) => currency
      );
      let currencyFilter = true;

      if (selectedCurrency) {
        console.log("selectedCurrency", selectedCurrency);
        currencyFilter =
          selectedCurrency &&
          (newFilter.selectCurrency.allChains ||
            (newFilter.selectCurrency.flr &&
              item.data.selectedBlockchain.toLowerCase() === "flare network") ||
            (newFilter.selectCurrency.sgb &&
              item.data.selectedBlockchain.toLowerCase() ===
              "songbird network"));
      }

      // Filter by price
      let priceFilter;
      if (
        newFilter.selectPrice.min === "" &&
        newFilter.selectPrice.max === ""
      ) {
        //console.log("default price");
        priceFilter = true;
      } else {
        //console.log("not all");
        if (item.isSaleListed) {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.listedData.price) >=
              parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.listedData.price) <=
              parseFloat(newFilter.selectPrice.max));
        }
        if (item.isOfferListed) {
          priceFilter =
            (newFilter.selectPrice.min === "" ||
              parseFloat(item.listedData.minimumBid) >=
              parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              parseFloat(item.listedData.minimumBid) <=
              parseFloat(newFilter.selectPrice.max));
        }
      }
      let statusFilter = false;

      if (
        newFilter.selectStatus.buyNow ||
        newFilter.selectStatus.onOffer ||
        newFilter.selectStatus.new
      ) {
        //console.log("isListed", item.isListed);
        statusFilter =
          (newFilter.selectStatus.buyNow && item.isSaleListed) ||
          (newFilter.selectStatus.onOffer && item.isOfferListed) ||
          (newFilter.selectStatus.new && !item.isListed);
      } else {
        statusFilter = true;
      }

      console.log("statusFilter", statusFilter);
      console.log("priceFilter", priceFilter);
      console.log("currencyFilter", currencyFilter);
      console.log("categoriesFilter", categoriesFilter);

      // Return item if all filters pass
      if (categoriesFilter && currencyFilter && priceFilter && statusFilter) {
        filteredData.push(item);
      }
    });
    //   setNftFilteredDetails(filteredData);
  };
  const navigateToSingleArtwork = (item) => {

    console.log("navigateToSingleArtwork", item);

    try {
      let collectionId = item?.metadata?.data.selectedCollectionId;
      let tokenId = item?.data.tokenId;
      let network = item?.metadata?.data?.selectedBlockchain;

      navigate(`/artwork/${network}/${collectionId}/${tokenId}/`);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  // get nfts of collected user (return token id )

  const getUserNftsFromContract = async () => {
    let web3_1;
    if (collectionDetails?.selectedNetwork === config.networks.songbird) {
      web3_1 = new Web3(config.rpc.songbird);
    } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
      web3_1 = new Web3(config.rpc.flr);
    }

    try {
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );
        const contractOwner = await contract.methods.owner().call();

        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenId(collectionDetails?.address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.error(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    resolve(a.tokenId);
                  }
                } else {
                  console.log("error");
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // get token ids of nfts that are not listed

  const getTokenIdforNoList = async () => {
    try {
      let TokenId = await getUserNftsFromContract();
      let tokenId = TokenId;
      return tokenId;
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    const getToken = async () => {
      let res = await getTokenIdforNoList();
      console.log(res);
      setTokenId(res);
    };

    getToken();
  }, [collectionDetails]);

  return (
    <div>
      <div
        className={
          props.flag ? "d-flex align-item-center justify-content-between" : ""
        }
      >
        <div className={props.flag ? "my-filter w-25" : "d-none"}>
          <AsideFilterExplore
            onSelectedFilterChange={props.onSelectedFilterChange}
          />
        </div>
        <div
          className={`grid-display layout-artwork  ${props.flag ? "w-75 active" : ""
            }`}
        >
          {nftFilteredDetails &&
            nftFilteredDetails.map((item, index) => {
              console.log(item, "item in artowrk ");
              return (
                <div onClick={() => navigateToSingleArtwork(item)} className="collection-grid-card" key={index}>
                  <div className="card-body">
                    <div

                      className="art-img"
                    >
                      {item?.metadata?.data?.image ? (
                        <img
                          src={item?.metadata?.data?.image}
                          alt="art"
                          className="img-100 artwork-hover"
                        />
                      ) : (
                        <img
                          src={item?.metadata?.data?.previewImg}
                          className="img-100 artwork-hover"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
